<template>
  <div class="info-item">
    <section class="login-section">
      <div class="wrapper">
        <nav>杭州雨果科技有限公司</nav>
        <div class="account-box">
          <div class="toggle">务实-创新-追求卓越</div>
          <div class="form">
            <div class="form-item">
              <div class="video-play">
                <BaseVideoPlayer :options="videoOption"></BaseVideoPlayer>
              </div>

              <div class="agree">
                轴承对于我们大多人来说是比较陌生了，但是我们日常生活中也是常接触到的，只是不太了解而已，
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import { findBrand } from '@/api/home'
import { useLazyData } from '@/hooks'
export default {
  name: 'HomeBrand',
  components: {  },
  setup () {
  
let videoOption = reactive({
  autoplay: true,
  controls: true,
  sources: [
    {
      src: "http://demo.hyxdsoft.com/img/templateImg/web1001/test.mp4",
      type: "video/mp4",
    },
  ],
  width: "640px",
  height: "360px",
});
return { videoOption: videoOption }
  }
 
}
</script>

<style scoped lang="less">
.info-item {
  background-color: #fff;
  padding: 20px;
}

// 主体样式
.login-section {
  background: url(../../../assets/images/bg.jpg) no-repeat center / cover;
  height: 688px;
  position: relative;
  .wrapper {
    width: 680px;
    position: absolute;
    left: 50%;
    margin-left: -28%;
    top: 54px;
    nav {
      font-size: 24px;
      height: 55px;
      padding: 20px 40px;
      text-align: center;
      align-items: center;
      color: #fff;
    }
  }
}

// 账号容器
.account-box {
  .toggle {
    padding: 10px 40px;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }
  .form {
    padding: 20px 10px;
    &-item {
      margin-bottom: 28px;
    }
    .video-play {
      border: 10px solid #e4e4e4;
    }
    .agree {
      font-size: 16px;
      padding: 10px 20px;
      align-items: center;
      color: #fff;
    }
  }
}

.info-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  .head {
    height: 50px;
    line-height: 50px;
    background: #f5f5f5;
    padding: 0 20px;
    overflow: hidden;
    span {
      margin-right: 20px;
      &.down-time {
        margin-right: 0;
        float: right;
        i {
          vertical-align: middle;
          margin-right: 3px;
        }
        b {
          vertical-align: middle;
          font-weight: normal;
        }
      }
    }
    .del {
      margin-right: 0;
      float: right;
      color: #999;
    }
  }
  .body {
    display: flex;

    flex-wrap: wrap;
    align-items: stretch;
    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;
      > p {
        padding-top: 10px;
      }
      &:first-child {
        border-left: none;
      }
      &.products {
        flex: 1;
        padding: 0;
        align-self: center;
        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 10px;
            display: flex;
            &:last-child {
              border-bottom: none;
            }
            .image {
              width: 100px;
              height: 100px;
              border: 1px solid #f5f5f5;
            }
            .info {
              width: 220px;
              text-align: left;
              padding: 0 10px;
              p {
                margin-bottom: 5px;
                &.name {
                  height: 38px;
                  font-weight: bold;
                  padding: 10px;
                  font-size: 18px;
                }
                &.attr {
                  color: #999;
                  font-size: 16px;
                  span {
                    margin-right: 5px;
                  }
                }
              }
            }
            .enterprise {
              width: 100px;
            }
            .count {
              width: 80px;
            }
          }
        }
      }
      &.state {
        width: 120px;
        .green {
          color: #999;
        }
      }
      &.amount {
        width: 200px;
        .red {
          color:#999;
        }
      }
      &.action {
        width: 140px;
        a {
          display: block;
          &:hover {
            color: #999;
          }
        }
      }
    }
  }
}
</style>
