<template>
  <div class="home-product" ref="target">
      <div class="box">
        <ul class="productadv-list">
          <li
            v-for="productadvInfo in productadvList"
            :key="productadvInfo.id"
          >
            <div class="productadv-item">
              <RouterLink to="/" class="image">
                <div class="point_top">
                  <p>
                    {{ productadvInfo.name }}<br />{{ productadvInfo.summary }}
                  </p>
                </div>
              </RouterLink>
              <p class="name ellipsis-2">{{ productadvInfo.desc }}</p>
            </div>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { findBrand } from '@/api/home'
import { useLazyData } from '@/hooks'
export default {
  name: 'HomeBrand',
  components: {  },
  setup () {
     const { target, result } = {
    "target": "操作成功",
    "result": [
            {
                "id": "3995139",
                "name": "Absorbed",
                "summary": "专注",
                "desc": "特定的技术解决方案",
            },
            {
                "id": "3995139",
                "name": "DelveInto",
                "summary": "钻研",
                "desc": "适用于新产品或新",
            },
            {
                "id": "3995139",
                "name": "Major",
                "summary": "专业",
                "desc": "帮助预测技术发",
            },
            {
                "id": "3995139",
                "name": "Power",
                "summary": "力量",
                "desc": "规划和协调技术",
            },
            {
                "id": "3995139",
                "name": "Scientific",
                "summary": "科研",
                "desc": "以更全面的方式",
            }
        ]
}
    return { productadvList: result, target }
  }
}
</script>
<style scoped lang="less">
.home-product {
  background: #fff;
  // height: 2900px;

  .box {
    display: flex;
    .cover {
      width: 240px;
      height: 610px;
      margin-right: 10px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .label {
        width: 188px;
        height: 66px;
        display: flex;
        font-size: 18px;
        color: #fff;
        line-height: 66px;
        font-weight: normal;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        span {
          text-align: center;
          &:first-child {
            width: 76px;
            background: rgba(0, 0, 0, 0.9);
          }
          &:last-child {
            flex: 1;
            background: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
    .productadv-list {
      width: 1240px;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 240px;
        height: 300px;
        margin-right: 10px;
        margin-bottom: 10px;
        &:nth-last-child(-n + 4) {
          margin-bottom: 0;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
.productadv-item {
  width: 240px;
  height: 300px;
  padding: 10px 30px;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  transition: all 0.5s;
  .image {
    display: block;
    width: 160px;
    height: 160px;
    margin: 0 auto;
    .point_top {
      width: 128px;
      height: 128px;
      border-radius: 64px;
      background: #0075c2;
      cursor: pointer;
      transition: background 1.5s;
      -webkit-transition: all 0.8s ease-in-out;
      -moz-transition: all 0.8s ease-in-out;
      -o-transition: all 0.8s ease-in-out;
      transition: all 0.8s ease-in-out;
      -ms-transition: all 0.8s ease-in-out;
      p {
        color: #fff;
        line-height: 25px;
        font-size: 16px;
        padding-top: 45px;
        text-align: center;
      }
    }
    .point_top:hover {
      background: #2f383d;
      transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;
      -ms-transition: all 0.8s ease-in-out;
    }
  }
  p {
    margin-top: 6px;
    font-size: 16px;
    &.name {
      height: 44px;
    }
    &.desc {
      color: #666;
      height: 22px;
    }
    &.enterprise {
      margin-top: 10px;
      font-size: 20px;
      color: #0075c2;
    }
  }
  &:hover {
    border-color: #0075c2;
    .extra {
      transform: none;
    }
  }
}

.text-split-line {
  background: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  height: 160px;
  padding: 0 80px;
  .info {
    padding-left: 10px;
    flex: 1;
    p {
      &:first-child {
        font-size: 26px;
        margin-bottom: 10px;
      }
      &:last-child {
        color: #999;
        font-size: 16px;
      }
    }
  }
}

.productadv-image {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
}
</style>
